import React from 'react';
import './App.css';

function App() {
    return (
        <div className="App">
            <h1 className="App-header">Welcome to Blockus!</h1>
        </div>
    );
}

export default App;
